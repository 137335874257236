import React from "react";

const PrimaryButton = ({ text, onClick, type }) => {
  return (
    <div className="py-3 w-full justify-center">
      <button type={type ? type : 'button'} className=" w-full min-w-[120px] py-3 px-4 rounded-lg bg-red hover:bg-redDark button-jost cursor-pointer flex justify-center gap-3 items-center" onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

export default PrimaryButton;
