import React from "react";
//components
import PriceCard from './PriceCard';
import { useSelector } from "react-redux";

const PriceContainer = () => {
    const pricesList = useSelector(state => state.price);
    return (
        <div className='flex max-lg:flex-col max-sm:gap-2 gap-4 max-sm:px-4 max-lg:px-10 px-24'>
            <PriceCard items={pricesList.slice(0, 5)} />
            <PriceCard items={pricesList.slice(5, 7)} />
        </div>
    )
}

export default PriceContainer;