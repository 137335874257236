import React from 'react';
import Input from '../shared/Input';
import PrimaryButton from '../shared/PrimaryButton';

const CateringForm = () => {
  return (
    <div className=' w-[98%] p-5 shadow-white rounded-lg'>
        <form className='flex flex-col gap-3 items-center'>
            <Input id = 'name' label='Namn/Företag:' />
            <Input id = 'contact' label='Kontaktperson:' />
            <Input id = 'email' label='E-post:' />
            <Input id = 'adress' label='Adress:' />
            <Input id = 'date' label='Datum och tid:' />
            <Input id = 'menu' label='Önskad meny/menyer:' />
            <Input id = 'kuvert' label='Antal kuvert:' />
            <Input id = 'allergic' label='Övrigt (allergier/önskemål osv.):' />
            <PrimaryButton text='Skicka förfrågan' />
        </form>
    </div>
  )
}

export default CateringForm;