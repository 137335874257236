import FoodCard from './FoodCard';

const FoodCardContainer = ({data}) => {
  return (
    <div className='flex max-xl:flex-col max-sm:px-4 max-lg:px-10 px-24 gap-4'>
        {
            data.map(i => <FoodCard key={i.title} title={i.title} text={i.text} img={i.img} />)
        }
    </div>
  )
}

export default FoodCardContainer;