import React from 'react';
import { FaX } from "react-icons/fa6";

const SuccessCard = ({setPopup}) => {
  return (
    <div className="bg-dark flex flex-col gap-5 w-[400px] px-6 py-4">
        <div className="flex justify-end pb-6">
          <FaX
            className="text-gray text-[22px] cursor-pointer max-sm:text-[18px]"
            onClick={() => setPopup(false)}
          />
        </div>
        <div className='flex flex-col items-center pb-6 gap-2'>
            <h5 className="h5-jost">Success!</h5>
            <p className='body-jost'>Request Successful</p>
        </div>
    </div>
  )
}

export default SuccessCard;