import React, { useState } from "react";
import { useSelector } from "react-redux";
//components
import Modal from "../shared/Modal";
import DashboardCard from "./DashboardCard";
import HeaderTitle from "../shared/HeaderTitle";
import Editor from "../shared/Editor";
import PriceList from "./PriceList";
import SuccessCard from "../Contact/SuccessCard";

const Dashboard = () => {
  const menuList = useSelector((state) => state.menu);
  const priceList = useSelector((state) => state.price);
  const foodList = useSelector((state) => state.food);
  const [popup, setPopup] = useState(false);
  const [edit, setEdit] = useState({
    shown: false,
    type: "",
    id: 0,
    content: "",
    isEdit: false,
  });

  return (
    <div className="flex flex-col">
      <HeaderTitle title="Dashboard" />
      <div className="pb-20 max-sm:px-4 max-lg:px-10 px-24 w-full">
        <div className="flex flex-col gap-6 pt-20 pb-10">
          <p className="p-3 bg-red h5-jost w-176">Veckans meny</p>
          {menuList.length !== 0 && (
            <DashboardCard setEdit={setEdit} edit={edit} setPopup={setPopup}/>
          )}
        </div>

        {priceList.length !== 0 && <PriceList edit={edit} setEdit={setEdit} />}

        <div>
          {menuList.length === 0 && (
            <button
              className="text-base cursor-pointer text-white mq960:text-xs w-32 rounded bg-red p-2"
              onClick={() => setEdit({ shown: true })}
            >
              Lägg till meny
            </button>
          )}
        </div>
      </div>
      {edit.shown && (
        <Modal>
          <Editor setPopup={setPopup} setEdit={setEdit} edit={edit} />
        </Modal>
      )}

      {popup && (
        <Modal>
          <SuccessCard setPopup={setPopup} />
        </Modal>
      )}
    </div>
  );
};

export default Dashboard;
