import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshMenu, removeMenuItem } from "../../reducer/MenuReducer";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { motion } from "framer-motion";

const DashboardCard = ({ setEdit, edit, setPopup }) => {
  const menuList = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (edit.delete === true) {
      dispatch(removeMenuItem(edit.id));
      dispatch(refreshMenu());
      setPopup(true);
    }
  };

  useEffect(() => {
    handleDelete();
  }, [edit]);

  return (
    <div>
      <div style={{ width: "100%" }}>
        {menuList?.map((menu, index) => (
          <div className="flex flex-col items-center w-full" key={index}>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="flex w-full justify-center shadow-white p-5 rounded-lg max-h-[500px] overflow-hidden hover:overflow-auto mb-20"
            >
              <div
                className="body-jost ckeimage animate-marquee-vertical hover:animate-none"
                dangerouslySetInnerHTML={{ __html: menu.content }}
              />
            </motion.div>

            <div className="flex gap-4">
              <button
                className="body-jost text-base bg-red w-auto text-center rounded p-2 flex gap-2 items-center"
                onClick={() =>
                  setEdit({
                    shown: true,
                    id: menu.id,
                    content: menu.content,
                    isEdit: true,
                    delete: false,
                  })
                }
              >
                <FaEdit />
                Redigera meny
              </button>
              <button
                className="body-jost text-base bg-red w-auto text-center rounded p-2 flex gap-2 items-center"
                onClick={() =>
                  setEdit({
                    shown: false,
                    id: menu.id,
                    content: menu.content,
                    isEdit: false,
                    delete: true,
                  })
                }
              >
                <FaRegTrashAlt />
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardCard;
