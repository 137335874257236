import { motion } from "framer-motion";

const FoodCard = ({ title, text, img }) => {
  return (
    <div
      className="flex flex-1 max-sm:flex-col shadow-white rounded p-2"
    >
      <img
        src={img}
        className="max-sm:h-24 h-40 max-sm:w-full w-48 md:w-40 object-cover sm:rounded-full"
        alt="food-card"
      />
      <div className="max-sm:px-0 max-xl:px-3 px-6 max-sm:py-2 flex flex-col max-sm:gap-1 gap-5 justify-center">
        <h6 className="max-sm:text-base h6-literata">{title}</h6>
        <p className="max-sm:text-sm body-jost">{text}</p>
      </div>
    </div>
  );
};

export default FoodCard;
