import React from "react";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/Nav/NavBar";

const Layout = ({ children }) => {
  return (
    <div>
      <NavBar />
      <div className="flex flex-col min-h-screen overflow-y-hidden ">
        <div className="pt-20 md:pt-32 bg-dark h-full grow max-w-screen-5xl">{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
