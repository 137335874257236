export const aLaCarteMenu = [
    {
      img: 'https://images.pexels.com/photos/2291347/pexels-photo-2291347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Oscars hamburgertallrik 200g'
    },
    {
      img: 'https://images.pexels.com/photos/2291347/pexels-photo-2291347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Kebabtallrik'
    },
    {
      img: 'https://images.pexels.com/photos/2291347/pexels-photo-2291347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Stekt falukorv med stekt ägg och stekt potatis'
    },
    {
      img: 'https://images.pexels.com/photos/2291347/pexels-photo-2291347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Bacon med stekt ägg och stekt potatis'
    },
    {
      img: 'https://images.pexels.com/photos/2291347/pexels-photo-2291347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Vegetarisk falafel'
    },
    {
      img: 'https://images.pexels.com/photos/2291347/pexels-photo-2291347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Lövbiff'
    }
  ]