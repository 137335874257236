import axios from 'axios';
import { baseUrl } from './config';

export const login = async (credentials) => {
  try {
    const response = await axios.post(baseUrl + '/login/', JSON.stringify(credentials), {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept':'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true
        }
    });
    if(!response.data.access_token){
      window.alert(response.data.message);
    }
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const refreshToken = async (credentials) => {
  try {
    const response = await axios.post(`${baseUrl}refresh/`, JSON.stringify(credentials), {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept':'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true
      }
    });
    if(!response.data.access_token){
      window.alert(response.data.message);
    }
    return response.data;
  } catch (err) {
    console.log(err);
  }
}