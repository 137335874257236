import PriceItem from './PriceItem';

const PriceCard = ({ items }) => {
  if (!Array.isArray(items)) {
    return null;
  }
  return (
    <div className='flex flex-col flex-1 gap-3 max-sm:px-0 max-lg:px-20 px-12'>
      {
        items.map(i => <PriceItem key={i.id} item={i} />)
      }
    </div>
  )
}

export default PriceCard;