import React from 'react';
import { cateringData } from '../../dataStore/CateringDescriptionData';

const CateringTextContainer = () => {
  return (
    <div className='flex flex-col gap-4 max-sm:pr-0 pr-14'>
        {
            cateringData.map(i => <p key={i} className='max-sm:text-sm body-jost text-center' >{i}</p>)
        }
    </div>
  )
}

export default CateringTextContainer;