import axios from 'axios';
import { config, baseUrl } from './config';

export const getAllFoods = async () => {
    const request = axios.get(baseUrl + '/foods/');
    try {
        const response = await request;
        return response.data;
    } catch (err) {
        console.log(err);
    }
}

// export const getOneFood = async (id) => {
//     const request = axios.get(`${baseUrl}/${id}`);
//     const response = await request;
//     return response.data;
// }

export const updateFood = async (id, object) => {
    const request = axios.put(`${baseUrl}/${id}`, object, config);
    try {
        const response = await request;
        return response.data;
    } catch (err) {
        console.log(err);
    } 
}

export const createFood = async (object) => {
    const request = axios.post(baseUrl, object, config);
    try {
        const response = await request;
        return response.data;
    } catch (err) {
        console.log(err);
    }
    
}

export const deleteFood = async (id) => {
    const request = axios.delete(`${baseUrl}${id}`, config);
    try {
        const response = await request;
        return response.data;
    } catch (err) {
        console.log(err);
    }
}