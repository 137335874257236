import axios from 'axios';
import { config, baseUrl} from './config';

export const getAllPrices = async () => {
    const request = axios.get(baseUrl + '/prices/');
    try {
        const response = await request;
        return response.data;
    } catch (err) {
        console.log(err);
    }
}

// export const getPrice = async (id) => {
//     const request = axios.get(`${baseUrl}${id}`);
//     const response = await request;
//     return response.data;
// }

export const updatePrice = async (id, object) => {
    const request = axios.put(`${baseUrl}${id}`, object, config);
    try {
        const response = await request;
        return response.data;
    } catch (err) {
        console.log(err)
    }
}

export const createPrice = async (newObject) => {
    const request = axios.post(baseUrl, newObject, config);
    try {
        const response = await request;
        return response.data;
    } catch (err) {
        console.log(err);
    }
}