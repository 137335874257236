export const buffeMenu = [
    {
      img: 'https://images.pexels.com/photos/2291347/pexels-photo-2291347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Salladsbuffé',
      text: 'Kombinera din egen salladstallrik - välj mellan 20 olika sorters grönsaker.'
    },
    {
      img: 'https://images.pexels.com/photos/262947/pexels-photo-262947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Soppbuffé',
      text: 'Varje torsdag - ät så mycket ni kan av vår populära soppbuffé med hemlagade pannkakor med tillbehör.'
    },
    {
      img: 'https://images.pexels.com/photos/10296429/pexels-photo-10296429.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Tacobuffé',
      text: 'Varje onsdag kan ni äta taco med dess alla klassiska tillbehör.'
    },
    {
      img: 'https://images.pexels.com/photos/1383787/pexels-photo-1383787.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Glassbuffé',
      text: 'Vi bjuder PÅ GLASSBUFFÉ VARJE DAG. Gäller endast vid köp av lunch.'
    }
  ]