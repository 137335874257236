import React from 'react';

const Input = ({label, type, placeholder, id, handleChange, name, value}) => {
  return (
    <div className='flex flex-col gap-1 w-full'>
        <label className='h6-jost'>{label}</label>
        <input
            className='body-jost-small appearance-none placeholder:text-gray-40 bg-dark h-14 px-3 border-b border-b-gray focus:outline-none focus:border-b-2 valid:border-b-2 focus:bg-gray5'
            placeholder={placeholder}
            type={type}
            id={id}
            onChange={handleChange}
            required
            name={name}
            value={value}
        />
    </div>
  )
}

export default Input;