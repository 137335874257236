import React, { useEffect, useState } from "react";
import PrimaryButton from "./PrimaryButton";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch } from "react-redux";
import { editMenuItem, refreshMenu } from "../../reducer/MenuReducer";
import { createMenu } from "../../services/menu";
import { FaX } from "react-icons/fa6";

const Editor = ({ setPopup, setEdit, edit }) => {
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const handleChangeData = (e, editor) => {
    setContent(editor.getData());
  };

  useEffect(() => {
    if (edit.isEdit) {
      setContent(edit.content);
    }
  }, [edit]);

  const newObject = {
    content: content,
  };

  const handleSaveMenu = async (e) => {
    e.preventDefault();

    try {
      if (edit.isEdit) {
        dispatch(editMenuItem(edit.id, newObject));
        setEdit({ isEdit: false });
        dispatch(refreshMenu());
        setPopup(true);
      } else {
        const data = await createMenu(newObject);
        if (data) {
          setContent("");
          setEdit({ shown: false });
          setPopup(true);
          dispatch(refreshMenu());
        }
      }
    } catch (exception) {
      console.error("saving menu error", exception);
    }
  };

  return (
    <div className="bg-dark text-black h-full grow max-w-screen-5xl p-4">
      <div className="flex justify-end pb-6">
        <FaX
          className="text-gray text-[22px] cursor-pointer max-sm:text-[18px]"
          onClick={() => {
            setEdit({ shown: false });
          }}
        />
      </div>
      <form className="flex flex-col gap-4 pb-2">
        <CKEditor
          editor={ClassicEditor}
          onChange={handleChangeData}
          data={content}
          className="w-full ck-content"
          config={{
            toolbar: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "link",
              "insertTable",
              "blockQuote",
              "mediaEmbed",
              "|",
              "bulletedList",
              "numberedList",
              "outdent",
              "indent",
            ],
            heading: {
              options: [
                {
                  model: "paragraph",
                  title: "Paragraph",
                  class: "ck-heading_paragraph",
                },
                {
                  model: "heading1",
                  view: "h1",
                  title: "Heading 1",
                  class: "ck-heading_heading1",
                },
                {
                  model: "heading2",
                  view: "h2",
                  title: "Heading 2",
                  class: "ck-heading_heading2",
                },
              ],
            },
            table: {
              contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
            },
          }}
        />
        <PrimaryButton
          text="Save"
          onClick={handleSaveMenu}
          type="submit"
          className="max-lg:px-12 flex items-start"
        />
      </form>
    </div>
  );
};

export default Editor;
