import { createSlice } from '@reduxjs/toolkit';
import { getAllPrices, updatePrice } from '../services/prices';

const priceSlice = createSlice({
    name: 'price',
    initialState: [],
    reducers: {
        // eslint-disable-next-line
        setPriceList(state, action) {
            return action.payload;
        },
        // eslint-disable-next-line
        clearPriceList(state, action) {
            return [];
        },
        updatePriceList(state, action) {
            const newState = state.map(i => {
                if(i.id !== action.payload.id){
                    return i;
                }else{
                    return action.payload;
                }
            })
            return newState;
        }
    }
});

export const savePriceList = () => {
    return async dispatch => {
        const newPriceList = await getAllPrices();
        dispatch(setPriceList(newPriceList));
    };
};

export const editPrice = (id, object) => {
    return async dispatch => {
        const newPrice = await updatePrice(id, object);
        dispatch(updatePriceList(newPrice));
    };
};

export const { setPriceList, clearPriceList, updatePriceList } = priceSlice.actions;
export default priceSlice.reducer;