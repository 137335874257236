import axios from "axios";
import { config, baseUrl } from "./config";

const url = `${baseUrl}/menus`;
export const getAllMenu = async () => {
  const request = axios.get(`${url}/`);
  try {
    const response = await request;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getOneMenu = async (id) => {
  const request = axios.get(`${baseUrl}${id}`);
  try {
    const response = await request;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateMenu = async (id, object) => {
  try {
    const response = axios.put(`${url}/${id}`, object, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const createMenu = async (newObject) => {
  try {
    const response = await axios.post(`${url}/`, newObject, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const deleteMenu = async (id) => {
  try {
    const response = await axios.delete(`${url}/${id}`, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
