import { motion } from "framer-motion";

const ALaCarteCard = ({ title, img }) => {
  return (
    <div
      className="flex flex-1 shadow-white p-2 rounded-md"
    >
      <img
        src={img}
        className="max-xl:h-28 max-sm:h-20 h-40 max-xl:w-36 max-sm:w-28 w-48 md:w-40 md:rounded-full object-cover"
        alt="food-card"
      />
      <div className="max-sm:px-2 max-xl:px-3 px-6 max-sm:py-2 flex flex-col max-sm:gap-1 gap-3 justify-center">
        <p className="max-sm:text-sm subtitle-literata">{title}</p>
      </div>
    </div>
  );
};

export default ALaCarteCard;
