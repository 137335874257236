import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// components
import { NavBarMenuItems } from "../../dataStore/NavBarMenu";
//reducer actions
import { logOutUser } from "../../reducer/UserReducer";

function NavBarMenu() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()

  const handleLogout = () => {
    dispatch(logOutUser());
    navigate("/");
  };

  return (
    <div className="flex items-center gap-20 mq895:gap-16 max-sm:px-4 max-md:flex-col max-md:gap-10 max-md:items-start max-md:px-4 max-md:py-4">
      <div className="flex gap-12 mq895:gap-8 max-md:flex-col max-md:gap-4">
        {NavBarMenuItems.map((el, index) => (
          <Link key={index} to={el.route}>
            <p className={`text-[13px] font-medium text-[#D3D3D5] py-1 ${location.pathname === el.route && " border-b border-[#D3D3D5]"}`}>{el.menu}</p>
          </Link>
        ))}
      </div>
        {token ? (
          <div className="flex flex-row gap-6 md:invisible">
            <button className="body-jost" onClick={handleLogout}>
              Logga ut
            </button>
            <Link to="/dashboard">
              <p className="body-jost mq895:text-sm">Dashboard</p>
            </Link>
          </div>
        ) : (
          <Link to="/login" className=" md:invisible">
            <p className="body-jost mq895:text-sm">Logga in</p>
          </Link>
        )}
    </div>
  );
}

export default NavBarMenu;
