import React from "react";
import { motion } from "framer-motion";

function HeroMenu({ title, text, icon }) {
  return (
    <motion.div
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      className="flex flex-col text-left gap-5 w-full max-w-540 mq1341:max-w-sm mq1287:max-w-xs mq1047:w-280 mq735:w-full mq735:max-w-full p-5 rounded-md shadow-white md:min-w-[30%]"
    >
      <div className=" w-full flex justify-center">{icon}</div>

      <h6 className="h6-literata pr-px-4 max-sm:text-base text-center">
        {title}
      </h6>

      <p className=" flex-wrap body-jost max-sm:text-sm mq895:pl-0 mq895:pr-0 text-center">
        {text}
      </p>
    </motion.div>
  );
}

export default HeroMenu;
