export const menuData = [
    {
      img: 'https://images.pexels.com/photos/1448721/pexels-photo-1448721.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Husets dessert',
      text: 'Varje fredag bjuder vi på husets dessert! Beroende på dagens rätter finns olika valmöjligheter: ris, kokt- stekt, gratinerad potatis eller potatismos.'
    },
    {
      img: 'https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Vegetarisk mat',
      text: 'Vi serverar vegetarisk mat varje dag! Beroende på dagens rätter finns olika valmöjligheter: ris, alt. kokt, stekt, klyft, gratinerad potatis eller hemlagad potatismos.'
    }
  ]