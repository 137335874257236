const PriceItem = ({item}) => {
  return (
    <div className='flex'>
        <div className='w-full'>
            <h6 className='max-sm:text-base h6-jost'>{item.item}</h6>
            {item.description && <p className='body-jost max-sm:text-sm'>{item.description}</p>}
            
        </div>
        <div>
            <p className='h6-jost w-20 text-right max-sm:text-base'>{item.price} kr</p>
        </div>
    </div>
  )
}

export default PriceItem;