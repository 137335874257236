import ALaCarteCard from './ALaCarteCard';

const ALaCarteCardContainer = ({data}) => {
  return (
    <div className='flex max-sm:flex-col max-sm:gap-2 gap-4 max-sm:px-4 max-lg:px-10 px-24'>
        {
            data.map(i => <ALaCarteCard key={i.title} title={i.title} img={i.img} />)
        }
    </div>
  )
}

export default ALaCarteCardContainer;