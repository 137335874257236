export const menuSections = [
    {
        title: 'Veckans meny',
        text: 'Vi erbjuder en oförglömlig lunch med nya idéer varje vecka.'
    },
    {
        title: 'Buffer',
        text: 'Vi har ett brett utbud av varmrätter och olika bufféer att välja mellan.'
    },
    {
        title: 'À La Carte'
    },
    {
        title: 'Pris'
    }
]