import React from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const WeeklyMenuSwiper = () => {
  const menuList = useSelector((state) => state.menu);

  return (
    <div className="flex justify-center px-4 md:px-10">
      {menuList?.map((i) => (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          key={i.id}
          className="flex w-full justify-center shadow-white p-5 rounded-lg max-h-[500px] overflow-hidden hover:overflow-auto mb-20"
        >
          <div
            className="body-jost ckeimage animate-marquee-vertical hover:animate-none"
            dangerouslySetInnerHTML={{ __html: i.content }}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default WeeklyMenuSwiper;
