import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//components
import DashboardContainer from "../components/Dashboard/DashboardContainer.js";
import Layout from "./Layout";

const DashboardPage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if(token === null){
      navigate('/login');
    }
  }, [token]);

  return (
    <Layout>
      <div className="flex gap-24 w-full pb-20 max-lg:flex-col max-lg:pb-0">
        <DashboardContainer />
      </div>
    </Layout>
  );
};

export default DashboardPage;
