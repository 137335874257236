import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./styles/index.css";
import "./styles/normalize.css";
//pages
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import MenuPage from "./pages/MenuPage";
import CateringPage from "./pages/CateringPage";
import LoggainPage from "./pages/LoginPage";
//components
import DashboardPage from "./pages/DashboardPage";
import ErrorPage from "./pages/ErrorPage";
//reducer actions
import { saveMenuList } from "./reducer/MenuReducer";
import { savePriceList } from "./reducer/PriceReducer";
import { saveFoodList } from "./reducer/FoodReducer";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(saveMenuList());
      dispatch(savePriceList());
      dispatch(saveFoodList());
    } catch (exception) {
      console.log(exception);
    }
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/catering" element={<CateringPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/login" element={<LoggainPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
