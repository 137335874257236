import { createSlice } from "@reduxjs/toolkit";
import { deleteMenu, getAllMenu, updateMenu } from "../services/menu";

const menuSlice = createSlice({
  name: "menu",
  initialState: [],
  reducers: {
    // eslint-disable-next-line
    setMenuList(state, action) {
      return action.payload;
    },
    updateMenuList(state, action) {
      const newState = state.map((i) => {
        if (i.id !== action.payload.id) {
          return i;
        } else {
          return action.payload;
        }
      });
      return newState;
    },
    deleteMenuItem(state, action) {
      const newState = state.filter((item) => item.id !== action.payload.id);
      return newState;
    },
    // eslint-disable-next-line
    clearMenuList(state, action) {
      return [];
    },
  },
});

export const saveMenuList = () => {
  return async (dispatch) => {
    const newMenuList = await getAllMenu();
    dispatch(setMenuList(newMenuList));
  };
};

export const editMenuItem = (id, object) => {
  return async (dispatch) => {
    const newMenuItem = await updateMenu(id, object);
    if (newMenuItem) {
      dispatch(updateMenuList(newMenuItem));
    }
  };
};

export const removeMenuItem = (id) => {
  return async (dispatch) => {
    const deletedItem = await deleteMenu(id);
    if (deletedItem) {
      dispatch(deleteMenuItem(deletedItem));
      dispatch(refreshMenu());
    }
  };
};

export const refreshMenu = () => {
  return async (dispatch) => {
    const updatedMenu = await getAllMenu();
    dispatch(setMenuList(updatedMenu));
  };
};

export const { setMenuList, clearMenuList, updateMenuList, deleteMenuItem } =
  menuSlice.actions;
export default menuSlice.reducer;
