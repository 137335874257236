import React from "react";
import FooterContact from "./FooterContact";
import FooterHours from "./FooterHours";
import FooterLinks from "./FooterLinks";

const Footer = () => {
  return (
    <div className="bg-[#373535] flex justify-between py-8 max-sm:pb-2 mq600:flex-col max-sm:gap-12 max-sm:px-4 max-lg:px-10 px-24">
      <FooterLinks />
      <FooterContact />
      <FooterHours />
    </div>
  );
};

export default Footer;
