import React from "react";
import { FooterData } from "../../dataStore/FooterData";

function FooterContact() {
  return (
    <div className="flex flex-col gap-6 max-sm:gap-4">
      <h6 className="h6-literata max-sm:text-base mq735:text-lg">
        Kontakta oss
      </h6>
      {FooterData.map((el, index) => (
        <div key={index} className="flex items-center gap-3">
          <p className="text-xl text-gray mq735:text-lg">{el.icon}</p>
          {el.link ? (
            <a href={el.link} className="body-jost-small hover:cursor-pointer">
              {el.name}
            </a>
          ) : (
            <p className="body-jost-small">{el.name}</p>
          )}
        </div>
      ))}
    </div>
  );
}

export default FooterContact;
