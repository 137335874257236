import axios from 'axios';
import { baseUrl  } from './config';

export const sendEmail = async (object) => {
    const request = axios.post(`${baseUrl}/email/send`, object);
    try {
        const response = await request;
        return response;
    } catch (err) {
        console.log(err);
    }
}