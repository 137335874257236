import React from "react";
import HeroImage from "../../assets/hero-image.svg";
import { HeroSectionData } from "../../dataStore/HeroSectionData";
import HeroMenu from "./HeroMenu";
import PrimaryButton from "../shared/PrimaryButton";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const HeroSection = () => {
  return (
    <div>
      <div className="w-full flex flex-col justify-between gap-16 px-24 max-lg:px-10 bg-dark h-540 mq915:flex-row mq800:gap-12 mq914:px-0 mq914:h-full">
        <div className="flex flex-col flex-1 justify-center mq914:px-10 max-sm:px-4">
          <h1 className="text-[#D3D3D5] text-[45px] lg:text-[65px] font-semibold md:leading-[4rem] text-center md:text-start">
            37 år av tradition och kvalitet!
          </h1>
          <p className="h6-jost max-lg:body-jost text-center md:text-start">
            Oscars Lunchrestaurang verksamhet har varit etablerat i 37år med
            svensk husmanskost som fortfarande håller hög kvalite. Vi erbjuder
            en oförglömlig lunch med nya idéer varje vecka.
          </p>
          <div className="flex justify-center md:justify-start">
            <Link to="/menu">
              <PrimaryButton text="Visa menyn" />
            </Link>
          </div>
        </div>
        <motion.div
          className="flex-1 max-w-full h-full"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <img
            src={HeroImage}
            alt="oscars-lunch"
            className="h-540 object-cover mq914:h-80 w-full rounded-md"
          />
        </motion.div>
      </div>
      <div className="flex flex-col gap-10 md:flex-row justify-between py-20 px-4 md:px-24">
        {HeroSectionData.map((el, index) => (
          <HeroMenu
            key={index}
            text={el.text}
            title={el.title}
            icon={el.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroSection;
