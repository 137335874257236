import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
//components
import LoggaInForm from "../components/LoggaIn/LoggInForm";
import Layout from "./Layout";
import HeaderTitle from './../components/shared/HeaderTitle';

const LoginPage = () => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if(token !== null){
            navigate('/dashboard');
        }
    }, [user]);

    return (
        <Layout>
            <div className="bg-dark flex flex-col gap-24 max-lg:gap-10 w-full items-center">
                <HeaderTitle title='Logga in' />
                <LoggaInForm />
            </div>
        </Layout>

    );

};

export default LoginPage;