import React from "react";
import HeaderTitle from "../components/shared/HeaderTitle";
import CateringTextContainer from "../components/Catering/CateringTextContainer";
import CateringForm from "../components/Catering/CateringForm";
import Layout from "./Layout";

const CateringPage = () => {
  return (
    <Layout>
      <div className="flex flex-col gap-8 pb-16 items-center">
        <HeaderTitle title="Catering" />
        <div className="flex flex-col gap-10 items-center ">
          <CateringTextContainer />
          <CateringForm />
        </div>
      </div>
    </Layout>
  );
};

export default CateringPage;
