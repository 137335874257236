import React from "react";
import { Link } from "react-router-dom";
const FooterLinks = () => {
  return (
    <div className="flex flex-col gap-6 max-sm:gap-4">
        <h6 className="h6-literata max-sm:text-base mq735:text-lg">
        Snabblänkar
        </h6>
      <div className="flex flex-col gap-3">
        <Link to="/" className="body-jost-small hover:cursor-pointer">
          Startsidan
        </Link>
        <Link to="/menu" className="body-jost-small hover:cursor-pointer">
          Meny
        </Link>
        <Link to="/catering" className="body-jost-small hover:cursor-pointer">
          Catering
        </Link>
        <Link to="/contact" className="body-jost-small hover:cursor-pointer">
          Kontakta oss
        </Link>
      </div>
    </div>
  );
};

export default FooterLinks;
