import React from "react";

const Modal = ({ children }) => {
  return (
      <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 bg-black bg-opacity-50 rounded">
        <div className="z-20">{children}</div>
      </div>
  );
};

export default Modal;
