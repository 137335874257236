import React from "react";
import { useSelector } from 'react-redux';

const PriceList = ({edit, setEdit}) => {
  const pricesList = useSelector(state => state.price);

  return (
    <div className="font-jost flex flex-col gap-6">
      <h1 className="py-3 px-4 bg-red w-[69px] h5-jost">Pris</h1>
      <div className="grid grid-cols-3 gap-4 mq1000:grid-cols-2 max-sm:grid-cols-1">
        {
          pricesList.map(i => (
            <div className="flex justify-between items-center w-full max-w-[372px] mq1000:max-w-full" key={i.id}>
              <h6 className="h6-jost mq375:text-base">{i.item}</h6>
              <div className="flex gap-12 items-center mq735:gap-8">
                <p className="subtitle-jost mq1120:text-sm mq375:text-xs">
                  {i.price} kr
                </p>
                <button className="text-sm cursor-pointer mq1120:text-xs mq375:text-xs" onClick={() => setEdit({shown: true, type: 'pris', id: i.id, placeholder: [i.item, i.price], item: i})}>
                  <p className="button-jost uppercase opacity-85 hover:opacity-100">Edit</p>
                </button>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default PriceList;