import { createSlice } from '@reduxjs/toolkit';
import { login } from '../services/login';

const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        // eslint-disable-next-line
        setUser(state, action) {
            return action.payload;
        },
        // eslint-disable-next-line
        clearUser(state, action) {
            return null;
        }
    }
});

export const saveUser = credentials => {
    return async dispatch => {
        const newUser = await login(credentials);
        if(newUser.access_token){
            dispatch(setUser(newUser));
            localStorage.setItem('token', newUser.access_token);
        }
    };
};

export const logOutUser = () => {
    return dispatch => {
        dispatch(clearUser());
        localStorage.clear();
    } 
}

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;