import React from "react";

function FooterHours() {
  return (
    <div className="flex flex-col gap-16 max-sm:gap-6">
      <div>
        <h6 className="h6-literata max-sm:text-base mq735:text-lg">
          Öppettider
        </h6>
        <div className="flex gap-7 pt-4">
          <p className="body-jost-small">Måndag - Fredag :</p>
          <p className="body-jost-small">10:00 - 18:00</p>
        </div>
      </div>
      <h1 className="flex justify-end body-jost-small">
        Copyright 2023 Luday AB
      </h1>
    </div>
  );
}

export default FooterHours;
