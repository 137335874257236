import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import userReducer from "./UserReducer.js";
import menuReducer from "./MenuReducer.js";
import foodReducer from "./FoodReducer.js";
import priceReducer from "./PriceReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    menu: menuReducer,
    food: foodReducer,
    price: priceReducer
  },
  middleware: [thunk],
});