import { createSlice } from '@reduxjs/toolkit';
import { getAllFoods, updateFood, createFood, deleteFood } from '../services/food';

const foodSlice = createSlice({
    name: 'food',
    initialState: [],
    reducers: {
        // eslint-disable-next-line
        setFoodList(state, action) {
            return action.payload;
        },
        updateFoodList(state, action) {
            const newState = state.map(i => {
                if(i.food_id !== action.payload.id){
                    return i;
                }else{
                    return action.payload.object;
                }
            })
            return newState;
        },
        appendFoodList(state, action) {
            const newState = [...state, action.payload]
            return newState;
        },
        deleteFoodItemFromList(state, action) {
            const newState = state.filter(i => i.food_id !== action.payload);
            return newState;
        }
    }
});

export const saveFoodList = () => {
    return async dispatch => {
        const newFoodList = await getAllFoods();
        dispatch(setFoodList(newFoodList));
    };
};

export const editFoodItem = (id, object) => {
    return async dispatch => {
        const newFood = await updateFood(id, object);
        if(newFood){
            dispatch(updateFoodList(newFood));
        }
    };
};

export const addFoodItem = (object) => {
    return async dispatch => {
        const newFood = await createFood(object);
        if(newFood){
            dispatch(appendFoodList(newFood));
        }
    }
}

export const deleteFoodItem = (id) => {
    return async dispatch => {
        // eslint-disable-next-line
        const deletedFood = await deleteFood(id);
        dispatch(deleteFoodItemFromList(id));
    }
}

export const { setFoodList, updateFoodList, appendFoodList, deleteFoodItemFromList } = foodSlice.actions;
export default foodSlice.reducer;