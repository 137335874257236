import { IoFastFoodOutline } from "react-icons/io5";
import { GiOpenedFoodCan } from "react-icons/gi";
import { TiStarFullOutline } from "react-icons/ti";

export const HeroSectionData = [
    {
        icon: <IoFastFoodOutline className="icon text-4xl max-sm:text-xl text-white" />,
        title: 'Autentiska rätter med en modern twist',
        text: "Vi erbjuder en meny som kombinerar traditionella recept med moderna influenser. Våra kockar använder noggrant utvalda ingredienser för att skapa rätter som inte bara smakar fantastiskt, utan också berättar en historia."
    },
    {
        icon: <GiOpenedFoodCan className="icon text-4xl max-sm:text-xl text-white" />,
        title: 'Säsongsbetonade Menyer',
        text: "Our menus change with the seasons to take advantage of the freshest and most flavorful ingredients. By offering seasonal dishes, we ensure that each meal is a memorable experience filled with the best flavors of the season."
    },
    {
        icon: <TiStarFullOutline className="icon text-4xl max-sm:text-xl text-white" />,
        title: 'Vårt åtagende',
        text: "Alltid bästa kvalitet till konkurrenskraftiga priser. Vårt mål är att alltid möta gästens behov med bästa möjliga service."
    }
]