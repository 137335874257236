import React from "react";
import HeroSection from "../components/Home/HeroSection";
import Layout from "./Layout";

const HomePage = () => {
  return (
    <Layout>
      <HeroSection />
    </Layout>
  );
};

export default HomePage;
