import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import NavBarMenu from "./NavBarMenu";
//reducer actions
import { logOutUser } from "../../reducer/UserReducer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const NavBar = () => {
  const [isShownMenu, setIsShownMenu] = React.useState(false);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logOutUser());
    navigate("/");
  };

  const handleNavMenu = () => {
    setIsShownMenu(!isShownMenu);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-16 flex items-center justify-between bg-red z-10 px-4 md:px-24">
        <Link to="/">
          <h2 className="text-white font-semibold">Oscars Lunchrestaurang</h2>
        </Link>
        <div className="max-md:hidden">
          <NavBarMenu />
        </div>
        {token ? (
          <div className="flex flex-row gap-6 invisible md:visible">
            <Link to="/dashboard">
              <p className={`text-white text-[13px] font-semibold py-1 ${location.pathname === "/dashboard" && " border-b border-[#D3D3D5]"}`}>
                Dashboard
              </p>
            </Link>
            <button
              className="text-white text-[13px] font-semibold border border-white px-5 hover:bg-white hover:text-red py-1  rounded-md"
              onClick={handleLogout}
            >
              Logga ut
            </button>
          </div>
        ) : (
          <Link to="/login" className=" invisible md:visible">
            <p className=" text-white text-[13px] font-semibold border border-white px-5 hover:bg-white hover:text-red py-1  rounded-md">
              Logga in
            </p>
          </Link>
        )}
        <button onClick={handleNavMenu} className="max-md:inline md:hidden">
          {isShownMenu ? (
            <FaTimes className="text-white" />
          ) : (
            <FaBars className="text-white" />
          )}
        </button>
      </div>

      {isShownMenu && (
        <div className="fixed top-16 left-0 w-full bg-red md:hidden">
          <NavBarMenu />
        </div>
      )}
    </>
  );
};

export default NavBar;
