import React, { useState } from "react";
import Input from "../shared/Input";
import PrimaryButton from "../shared/PrimaryButton";
import { sendEmail } from "../../services/email";
import HeaderTitle from "../../components/shared/HeaderTitle";

const ContactForm = ({setPopup}) => {
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSendMail = (e) => {
    e.preventDefault();

    if(name.length === 0 || mail.length === 0 || number.length === 0 || message.length === 0){
      window.alert('all fields must be filled in order to send message');
    }else if(number.length < 10){
      window.alert('phone number is too short');
    }else if(number.length > 10){
      window.alert('phone number is too long');
    }else if(!mail.includes('@')){
      window.alert('invalid email');
    }else{
      try {
        const newObject = {message: message, email: mail, name: name, phone_number: number};
        sendEmail(newObject);
        setName('');
        setNumber('');
        setMessage('');
        setPopup(true);
      }
      catch (exception) {
        console.log('sending email error');
      }
    }
  }

  return (
    <div className="max-lg:px-10 max-sm:px-4 flex flex-col gap-5 w-full">
       <HeaderTitle title="Kontakta oss" />
      <form className="flex flex-col gap-3 w-full items-center shadow-white p-5 rounded-lg">
        <Input label='Namn:' name='name' type='text' id='name' value={name} handleChange={({target}) => setName(target.value)} />
        <Input label='Din mailadress' name='mail' type='email' id='mail' value={mail} handleChange={({target}) => setMail(target.value)} />
        <Input label='Din mobilnummer:' name='number' type='number' value={number} handleChange={({target}) => setNumber(target.value)} />
        <Input label='Ditt meddelande:' name='message' type='text' value={message} handleChange={({target}) => setMessage(target.value)} />
        <PrimaryButton text='Skicka' onClick={handleSendMail} type='submit' />
      </form>
    </div>
  );
};

export default ContactForm;
