import React, { useState } from "react";
import ContactForm from "../components/Contact/ContactForm";
import Layout from "./Layout";
import Modal from "../components/shared/Modal";
import SuccessCard from "../components/Contact/SuccessCard";
import LocationPage from "../components/Contact/LocationPage";

const ContactPage = () => {
  const [popup, setPopup] = useState(false);
  return (
    <Layout>
      <div className=" grid grid-cols-1 lg:grid-cols-2 items-center w-full my-10 gap-10 px-4 md:px-24">
        <ContactForm setPopup={setPopup} />
        <LocationPage />
      </div>
      {popup && (
        <Modal>
          <SuccessCard setPopup={setPopup} />
        </Modal>
      )}
    </Layout>
  );
};

export default ContactPage;
