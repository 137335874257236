import { useSelector } from 'react-redux';
//components
import WeeklyMenuSwiper from "../components/Menu/WeeklyMenuSwiper";
import FoodCardContainer from "../components/Menu/FoodCardContainer";
import ALaCarteCardContainer from "../components/Menu/ALaCarteCardContainer";
import PriceContainer from "../components/Menu/PriceContainer";
import HeaderTitle from "../components/shared/HeaderTitle";
//data
import { menuSections } from "../dataStore/MenuSections";
import { buffeMenu } from "../dataStore/BuffeMenuData";
import { aLaCarteMenu } from "../dataStore/ALaCarteMenuData";
import { menuData } from "../dataStore/MenuData";
import Layout from "./Layout";

const MenuPage = () => {
  const menuList = useSelector(state => state.menu);
  const pricesList = useSelector(state => state.price);

  return (
    <Layout>
      <div className="flex flex-col gap-5 items-center -mt-10">
        { 
          menuList.length !== 0 &&
            <>
              <HeaderTitle
                title={menuSections[0].title}
                text={menuSections[0].text}
              />
              <WeeklyMenuSwiper />
            </>
        }
        <FoodCardContainer data={menuData} />
        <HeaderTitle
          title={menuSections[1].title}
          text={menuSections[1].text}
        />
        <div className="flex flex-col max-xl:gap-4 gap-6">
          <FoodCardContainer data={buffeMenu.slice(0, 2)} />
          <FoodCardContainer data={buffeMenu.slice(2, 4)} />
        </div>
        <HeaderTitle title={menuSections[2].title} />
        <div className="flex flex-col max-sm:gap-2 max-xl:gap-4 gap-6 w-full py-5 mb-10">
          <ALaCarteCardContainer data={aLaCarteMenu.slice(0, 2)} />
          <ALaCarteCardContainer data={aLaCarteMenu.slice(2, 4)} />
          <ALaCarteCardContainer data={aLaCarteMenu.slice(4, 6)} />
        </div>
        {
          pricesList.length !== 0 &&
            <>
              <HeaderTitle title={menuSections[3].title} />
              <PriceContainer />
            </>
        }
      </div>
    </Layout>
  );
};

export default MenuPage;