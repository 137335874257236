export const NavBarMenuItems = [
  {
    menu: "Startsidan",
    route: "/",
  },
  {
    menu: "Meny",
    route: "/menu",
  },
  {
    menu: "Catering",
    route: "/catering",
  },
  {
    menu: "Kontakta oss",
    route: "/contact",
  },
];
