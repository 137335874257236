import React from "react";
import Layout from "./Layout";

const ErrorPage = () => {
  return (
    <div>
      <Layout>
        <div className="flex justify-center items-center w-full h-full pt-40 max-sm:pt-10 max-sm:px-4 max-lg:px-10 px-24">
          <p className="h2-jost max-lg:text-4xl max-sm:text-xl">OBS! Sidan hittas inte</p>
        </div>
      </Layout>
    </div>
  );
};

export default ErrorPage;
