import {
  FaEnvelope,
  FaFacebook,
  FaLocationDot,
  FaPhone,
} from "react-icons/fa6";

export const FooterData = [
  {
    icon: <FaLocationDot  />,
    name: "Redegatan 9, 426 77 Västra Frölunda",
  },
  {
    icon: <FaPhone />,
    name: "031-69 07 33",
  },
  {
    icon: <FaEnvelope />,
    name: "info@oscarslunchrestaurang.se"
  },
  {
    icon: <FaFacebook />,
    name: "facebook.com/oscarslunchrestaurang",
    link: "https://www.facebook.com/oscarslunchrestaurang"
  },
];