import React from "react";
import HeaderTitle from "../../components/shared/HeaderTitle";

const LocationPage = () => {
  return (
    <div className="flex flex-col gap-5">
      <HeaderTitle title="Hitta Oss" />
      <div className="bg-white rounded-md min-h-60 p-5 shadow-xl flex flex-col gap-5 w-full">
        <div className=" grid grid-col-1 overflow-auto">
          <iframe
            title="Locate us"
            className=" w-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2133.602650664457!2d11.877523877004291!3d57.672237473854686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464f8d26a728cac3%3A0x4f9c73ff64f90817!2sOscars%20Lunchrestaurang!5e0!3m2!1sen!2sng!4v1721998854541!5m2!1sen!2sng"
            width="600"
            height="468"
            style={{ border: "0"}}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default LocationPage;
