import React, { useState } from "react";
import {  useDispatch } from 'react-redux';
//actions
import { saveUser } from "../../reducer/UserReducer";
//components
import PrimaryButton from "../shared/PrimaryButton";
import Input from "../shared/Input";

const LoggaInForm = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const newUser = {email, password};
            dispatch(saveUser(newUser));
            setEmail('');
            setPassword('');
          }
          catch (exception) {
            console.log('wrong credentials');
            console.log(exception);
          }
    }

    return(
        <div className="bg-dark max-sm:px-4 max-lg:px-10 px-24 pb-24 flex max-sm:justify-center w-full md:w-[70%] items-center justify-center">
            <form className=" w-96 flex flex-col gap-3 shadow-white p-5 rounded-lg" onSubmit={handleLogin}>
                <Input label="Email:" type='text' name='email' id='email' handleChange={({target}) => setEmail(target.value)} />
                <Input label ="Password:" type='password' name='password' id='password' handleChange={({target}) => setPassword(target.value)} />
                <PrimaryButton text="Logga in" type="submit" />
            </form>
        </div>
    );
};

export default LoggaInForm;